/* Slider */
.slick-slider {
    position: relative;
    width: 100%;
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
}

.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    width: 100%;

    &:focus,
    &:hover {
        outline: 0;
    }
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
    height: auto;
    width: 100%;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

/* Slider */

/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 48px;
    height: 48px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'FontAwesome';
    font-size: 48px;
    font-weight: bold;
    line-height: 1;

    opacity: .75;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: opacity 0.25s;
}

.slick-prev {
    left: 0;
    z-index: 1;
}

[dir='rtl'] .slick-prev {
    right: 0;
    left: auto;
}

.slick-prev:before {
    content: '\f104';
}

[dir='rtl'] .slick-prev:before {
    content: '\f105';
}

.slick-next {
    right: 0;
}

[dir='rtl'] .slick-next {
    right: auto;
    left: 0;
}

.slick-next:before {
    content: '\f105';
}

[dir='rtl'] .slick-next:before {
    content: '\f104';
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 0;
}

.slick-dots {
    position: absolute;
    bottom: 95px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}

.slick-dots li {
    position: relative;

    display: inline-block;

    width: 14px;
    height: 14px;
    margin: 0 3px;
    padding: 0;

    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 16px;
    height: 16px;
    padding: 0;

    cursor: pointer;

    color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

/**
 * Photo
 */

.slick-slider {
    .photo {
        background-size: cover;
        background-position: center center;
        padding-bottom: 5%;
        will-change: transform;

        &.is-animating {
            animation: scale 5s ease-in-out forwards;
        }

        &:before {
            content: "";
            display: block;
        }

        * > {
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}


@keyframes scale {
    0% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(1.1) rotate(0.01deg); // Rotate fixes shaking animation in some browsers. http://stackoverflow.com/questions/28992549/internet-explorer-and-css-transform-value-of-scale-on-keyframes
    }
}

/**
 * Wrapper
 */

.wrapper {
    margin-right: auto;
    margin-left: auto;
    max-width: 45em;
    width: 90%;
}