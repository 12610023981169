a {
  transition: all ease .3s;
}

.btn-light {
  font-weight: bold;
  color: $blue;
  i {
    transition: all ease .3s;
  }
  &:hover {
    border-color: #000;
    background-color: #fff;
    i {
      transform: translateX(5px);
    }
  }
}

i {
  display: inline-flex;
  &.arrow-right-blue {
    @include size(18px,13px);
    background: url("images/arrow-right-blue.png") no-repeat center center;
  }
  &.arrow-left-blue {
    @include size(18px,13px);
    background: url("images/arrow-left-blue.png") no-repeat center center;
  }
  &.arrow-right-white {
    @include size(18px,13px);
    background: url("images/arrow-right-white.png") no-repeat center center;
  }
}