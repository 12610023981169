$blue: #0033AC;
$gray-200: rgba(0,0,0,.2);
$gray-300: rgba(0,0,0,.3);
$gray-400: rgba(0,0,0,.4);
$gray-500: rgba(0,0,0,.5);
$gray-600: rgba(0,0,0,.6);
$gray-700: rgba(0,0,0,.7);
$gray-800: rgba(0,0,0,.8);


$link-hover-decoration: none;
$link-hover-color: $blue;


$btn-padding-x: 30px;
$btn-padding-y: 13px;
$btn-border-radius: 30px;


$input-btn-padding-y: .48rem;
$input-border-color: $gray-200;
$input-border-radius: 0;
$input-placeholder-color: $gray-300;



$font-family-base: 'Gilroy';