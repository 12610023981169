/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 12, 2018 */



@font-face {
  font-family: 'Gilroy';
  src: url('fonts/radomir_tinkov_-_gilroy-bold-webfont.woff2') format('woff2'),
  url('fonts/radomir_tinkov_-_gilroy-bold-webfont.woff') format('woff'),
  url('fonts/radomir_tinkov_-_gilroy-bold-webfont.ttf') format('truetype'),
  url('fonts/radomir_tinkov_-_gilroy-bold-webfont.svg#gilroybold') format('svg');
  font-weight: bold;
  font-style: normal;

}




@font-face {
  font-family: 'Gilroy';
  src: url('fonts/radomir_tinkov_-_gilroy-extrabold-webfont.woff2') format('woff2'),
  url('fonts/radomir_tinkov_-_gilroy-extrabold-webfont.woff') format('woff'),
  url('fonts/radomir_tinkov_-_gilroy-extrabold-webfont.ttf') format('truetype'),
  url('fonts/radomir_tinkov_-_gilroy-extrabold-webfont.svg#gilroyextrabold') format('svg');
  font-weight: 900;
  font-style: normal;

}




@font-face {
  font-family: 'Gilroy';
  src: url('fonts/radomir_tinkov_-_gilroy-light-webfont.woff2') format('woff2'),
  url('fonts/radomir_tinkov_-_gilroy-light-webfont.woff') format('woff'),
  url('fonts/radomir_tinkov_-_gilroy-light-webfont.ttf') format('truetype'),
  url('fonts/radomir_tinkov_-_gilroy-light-webfont.svg#gilroylight') format('svg');
  font-weight: 300;
  font-style: normal;

}




@font-face {
  font-family: 'Gilroy';
  src: url('fonts/radomir_tinkov_-_gilroy-medium-webfont.woff2') format('woff2'),
  url('fonts/radomir_tinkov_-_gilroy-medium-webfont.woff') format('woff'),
  url('fonts/radomir_tinkov_-_gilroy-medium-webfont.ttf') format('truetype'),
  url('fonts/radomir_tinkov_-_gilroy-medium-webfont.svg#gilroymedium') format('svg');
  font-weight: 500;
  font-style: normal;

}




@font-face {
  font-family: 'Gilroy';
  src: url('fonts/radomir_tinkov_-_gilroy-regular-webfont.woff2') format('woff2'),
  url('fonts/radomir_tinkov_-_gilroy-regular-webfont.woff') format('woff'),
  url('fonts/radomir_tinkov_-_gilroy-regular-webfont.ttf') format('truetype'),
  url('fonts/radomir_tinkov_-_gilroy-regular-webfont.svg#gilroyregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

/*

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Bold.eot');
  src: url('fonts/Gilroy-Bold.woff2') format('woff2'),
  url('fonts/Gilroy-Bold.woff') format('woff'),
  url('fonts/Gilroy-Bold.ttf') format('truetype'),
  url('fonts/Gilroy-Bold.svg#Gilroy-Bold') format('svg'),
  url('fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-ExtraBold.eot');
  url('fonts/Gilroy-ExtraBold.woff') format('woff'),
  url('fonts/Gilroy-ExtraBold.ttf') format('truetype'),
  url('fonts/Gilroy-ExtraBold.svg#Gilroy-ExtraBold') format('svg'),
  url('fonts/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Light.eot');
  src: url('fonts/Gilroy-Light.woff2') format('woff2'),
  url('fonts/Gilroy-Light.woff') format('woff'),
  url('fonts/Gilroy-Light.ttf') format('truetype'),
  url('fonts/Gilroy-Light.svg#Gilroy-Light') format('svg'),
  url('fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Medium.eot');
  src: url('fonts/Gilroy-Medium.woff2') format('woff2'),
  url('fonts/Gilroy-Medium.ttf') format('truetype'),
  url('fonts/Gilroy-Medium.svg#Gilroy-Medium') format('svg'),
  url('fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Regular.eot');
  src: url('fonts/Gilroy-Regular.woff2') format('woff2'),
  url('fonts/Gilroy-Regular.woff') format('woff'),
  url('fonts/Gilroy-Regular.ttf') format('truetype'),
  url('fonts/Gilroy-Regular.svg#Gilroy-Regular') format('svg'),
  url('fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}



*/
