.site-header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 50px;
  .mobile-menu-button {
    display: none;
  }
  .main-menu {
    display: flex;
    align-items: center;
    ul.menu {
      margin: 0;
      display: flex;
      align-items: center;
      @include list-unstyled;
      li {
        margin-right: 20px;
        a {
          color: $gray-400;
          font-weight: bold;
          text-transform: uppercase;
          &:hover {
            color: $blue;
          }
        }
        &.current_page_item {
          a {
            border-bottom: 1px solid $gray-400;
          }
        }
      }
    }
    .btn-group {
      .btn {
        font-weight: 500;
        font-size: 16px;
        &:after {
          border: none;
          @include size(12px, 6px);
          background: url("images/arrow-down.png") no-repeat center center;
          position: relative;
          bottom: -1px;
          margin-left: 5px;
        }
      }
      .dropdown-menu {
        background: #FFFFFF;
        box-shadow: 0 2px 25px 0 rgba(0,0,0,0.10);
        border-radius: 16px;
        border: none;
        margin-top: 15px;
        position: relative;
        &:before {
          content: " ";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 6px 7px 6px;
          border-color: transparent transparent #ffffff transparent;
          position: absolute;
          top: -7px;
          right: 26px;
        }
        a {
          color: $gray-600;
          &:hover {
            background-color: transparent;
            color: $blue;
          }
        }
      }
    }
  }
  @media screen and (max-width: 667px) {
    position: inherit;
    padding: 10px 20px;
    img {
      width: 60px;
    }
    .mobile-menu-button {
      display: block;
      font-size: 24px;
    }
    .main-menu {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      transition: all ease .3s;
      flex-direction: column;
      left: 0;
      right: 0;
      top: 90px;
      z-index: 99;
      background-color: #fff;
      padding: 20px;
      transform: translateY(-50px);
      -webkit-box-shadow: 0px 49px 100px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 0px 49px 100px 0px rgba(0,0,0,0.2);
      box-shadow: 0px 49px 100px 0px rgba(0,0,0,0.2);
      ul.menu {
        flex-direction: column;
        li {
          margin-right: 0;
          a {
            padding: 10px;
            display: block;
          }
        }
      }
      .btn-group {
        width: 100%;
        display: block;
        .btn-primary {
          width: 100%;
          background-color: #fff;
          border: none;
          color: $blue;
          border-radius: 0;
          padding-bottom: 0;
          &:after {
            display: none;
          }
        }
        .dropdown-menu {
          display: block !important;
          position: relative !important;
          transform: none !important;
          width: 100%;
          a {
            text-align: center;
          }
          &:before {
            right: 50%;
            margin-right: -4px;
          }
        }
      }
    }
    &.active {
      .main-menu {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.main-slider {
  position: relative;
  .slide {
    position: relative;
    &:before {
      content: " ";
      background-image: linear-gradient(-180deg, rgba(255,255,255,0.94) 3%, rgba(255,255,255,0.71) 49%, rgba(255,255,255,0.65) 78%, #FFFFFF 99%);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      opacity: .9;
    }
    .text-holder {
      position: absolute;
      z-index: 9;
      width: 600px;
      height: 400px;
      left: 50%;
      margin-left: -300px;
      top: 50%;
      margin-top: -200px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      h4 {
        font-size: 60px;
        color: $blue;
        font-weight: 900;
        margin-bottom: 20px;
        max-width: 600px;
      }
      p {
        font-size: 28px;
        color: $blue;
        font-weight: 500;
        margin-bottom: 30px;
      }
      .btn {
        box-shadow: 0 2px 25px 0 rgba(0,0,0,0.10);
      }
    }
  }
  .arrows {
    a {
      background: #FFFFFF;
      box-shadow: 0 2px 25px 0 rgba(0,0,0,0.10);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      @include size(45px);
      z-index: 9;
      top: 50%;
      margin-top: -22.5px;
      &.prev {
        left: 50px;
      }
      &.next {
        right: 50px;
      }
    }
  }
  .slick-dots {
    width: auto;
    left: 50px;
    bottom: 50px;
    li {
      @include size(26px);
      button {
        @include size(26px);
        color: #000;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        border: 2px solid #000;
      }
      &.slick-active {
        button {
          background-color: #000;
          color: #fff;
        }
      }
    }
  }
  .follow {
    position: absolute;
    right: 50px;
    bottom: 50px;
    display: flex;
    align-items: center;
    h6 {
      font-size: 20px;
      line-height: 20px;
      margin: 0 55px 0 0;
      font-weight: bold;
      position: relative;
      &:after {
        content: " ";
        width: 32px;
        border-bottom: 2px solid #000;
        position: absolute;
        right: -48px;
        top: 50%;
        margin-top: -1px;
      }
    }
    ul {
      @include list-unstyled;
      margin: 0;
      display: flex;
      align-items: center;
      li {
        a {
          color: #000;
          font-size: 20px;
          padding: 0 8px;
        }
      }
    }
  }
  @media screen and (max-width: 667px) {
    .slide {
      .text-holder {
        width: 100%;
        margin-left: 0;
        margin-top: 0;
        left: auto;
        top: auto;
        padding: 30px;
        h4 {
          font-size: 30px;
          text-align: center;
          margin-top: 40px;
        }
        p {
          font-size: 20px;
          display: block;
          text-align: center;
          width: 100%;
        }
        .btn {
          display: table;
          margin: 0 auto;
        }
      }
      img {
        height: 500px;
        width: auto;
      }
    }
    .arrows {
      display: none;
    }
    .slick-dots {
      left: 30px;
    }
    .follow {
      right: 30px;
    }
  }
}

section.products {
  padding: 70px 0;
  h2 {
    font-size: 52px;
    font-weight: 900;
    color: $blue;
    margin-bottom: 20px;
  }
  p {
    font-size: 24px;
    color: $gray-500;
    margin-bottom: 30px;
  }
  .btn {
    box-shadow: 0 2px 25px 0 rgba(0,0,0,0.10);
  }
  @media screen and (max-width: 667px) {
    padding: 30px 10px;
    h2 {
      font-size: 36px;
    }
    p {
      font-size: 18px;
    }
    .btn {
      margin-bottom: 30px;
    }
  }
}

.product-box, .link-box {
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  figure {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
  h4 {
    font-size: 18px;
    font-weight: bold;
    color: $gray-800;
    text-align: center;
    height: 40px;
  }
  &:hover {
    box-shadow: 0 2px 25px 0 rgba(0,0,0,0.10);
  }
}

.link-box {
  padding: 30px 20px 20px;
  margin-bottom: 30px;
  h4 {
    height: 40px;
    text-align: center;
    margin-bottom: 20px;
  }
  p {
    color: $gray-400;
    text-align: center;
    height: 70px;
    margin-bottom: 20px;
  }
}

.news-box {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  img {
    width: 100%;
    height: auto;
  }
  .text-holder {
    position: absolute;
    left: 100px;
    right: 0;
    top: 120px;
    bottom: 0;
    background-color: #fff;
    padding: 20px;
    color: $black;
    .date {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    h4 {
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    span {
      font-size: 12px;
      border-bottom: 1px solid $black;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  &.archive {
    .text-holder {
      left: 80px;
      top: 100px;
    }
  }
}

section.news {
  background: #F3F3F3;
  padding: 70px;
  h2 {
    font-size: 52px;
    font-weight: 900;
    color: $blue;
    margin-bottom: 20px;
  }
  p {
    font-size: 24px;
    color: $gray-500;
    margin-bottom: 30px;
  }
  .btn {
    box-shadow: 0 2px 25px 0 rgba(0,0,0,0.10);
  }
  @media screen and (max-width: 667px) {
    padding: 30px 10px;
    h2 {
      font-size: 36px;
    }
    p {
      font-size: 18px;
    }
    .btn {
      margin-bottom: 30px;
    }
    .news-box {
      .text-holder {
        left: 60px;
        top: 80px;
      }
    }
  }
}

section.get-offer {
  padding: 70px 0 120px;
  h2 {
    font-size: 52px;
    font-weight: 900;
    color: $blue;
    margin-bottom: 20px;
  }
  p {
    font-size: 24px;
    color: $gray-500;
    margin-bottom: 30px;
  }
  ul.forms {
    @include list-unstyled;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      padding: 0 15px;
      a {
        font-weight: bold;
        color: $gray-400;
        border-bottom: 1px solid $gray-400;
        &.active {
          color: $blue;
          border-bottom-color: $blue;
        }
      }
    }
  }
  .form-holder {
    .form {
      padding: 30px;
      background: #FFFFFF;
      box-shadow: 0 2px 25px 0 rgba(0,0,0,0.10);
      border-radius: 10px;
      display: none;
      &.active {
        display: block;
      }
    }
    p {
      margin: 0;
    }
    form {
      label {
        font-weight: bold;
        font-size: 14px;
        color: $gray-400;
      }
      .form-group {
        margin-bottom: 20px;
      }
      .wpcf7-radio {
        margin-bottom: 20px;
        label {
          font-size: 16px;
        }
        .wpcf7-list-item.first {
          margin-left: 0;
        }
      }
    }
  }
  @media screen and (max-width: 667px) {
    padding: 30px 10px;
    h2 {
      font-size: 36px;
    }
    p {
      font-size: 18px;
    }
    ul.forms {
      li {
        a {
          text-align: center;
          font-size: 13px;
          display: block;
        }
      }
    }
  }
}

footer.site-footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 70px 0;
  background: url("images/footer-bg.png") no-repeat top center;
  background-size: cover;
  margin-top: -35px;
  p {
    text-align: center;
    font-weight: 500;
    opacity: .6;
  }
  .sticky-whatsapp {
    display: none;
    @media screen and (max-width: 667px) {
      display: block;
      position: fixed;
      right: 0;
      bottom: 0;
      z-index: 9;
    }
  }
}


.post-page {
  background: url("images/post-page-bg.jpg") no-repeat top center;
  background-size: cover;
  padding-top: 200px;
  padding-bottom: 120px;
  h1 {
    text-align: center;
    font-size: 52px;
    color: $blue;
    font-weight: 900;
    margin-bottom: 30px;
  }
  .content-holder {
    background: #FFFFFF;
    box-shadow: 0 2px 25px 0 rgba(0,0,0,0.10);
    border-radius: 10px;
    padding: 50px;
    font-size: 20px;
    line-height: 32px;
    color: $gray-600;
  }
  @media screen and (max-width: 667px) {
    padding-top: 70px;
    h1 {
      font-size: 32px;
    }
    .content-holder {
      padding: 20px;
      img, iframe {
        max-width: 100% !important;
        height: auto !important;
      }
    }
  }
}

.gallery-size-full {
  .gallery-item {
    img {
      width: 90%;
      height: auto;
      border: none !important;
    }
  }
}


.panel-search-page {
  background: url("images/post-page-bg.jpg") no-repeat top center;
  background-size: cover;
  padding-top: 200px;
  padding-bottom: 120px;
  h2 {
    text-align: center;
    font-size: 32px;
    color: $blue;
    font-weight: 900;
    margin-bottom: 30px;
  }
  form {
    margin-bottom: 80px;
    .input-group {
      box-shadow: 0 2px 25px 0 rgba(0,0,0,0.10);
      border-radius: 10px;
    }
    .form-control {
      height: 57px;
      font-size: 20px;
      padding-left: 20px;
      color: $gray-600;
      border-color: #fff;
      border-radius: 10px 0 0 10px;
      &:focus {
        box-shadow: none;
        border-color: $blue;
      }
    }
    .btn-primary {
      border-radius: 0 10px 10px 0;
      padding: 14px 20px;
      font-size: 18px;
    }
  }
  .search-modal {
    .close {
      position: absolute;
      right: 0;
      top: 0;
      background-color: #000;
      color: #fff;
      text-shadow: none;
      opacity: 1;
      padding: 0;
      @include size(30px);
      z-index: 9;
      border-radius: 50%;
      &:hover {
        color: #fff;
        opacity: 1;
      }
    }
    .modal-header {
      padding: 0;
    }
    .modal-body {
      padding: 30px 30px 15px;
      background-color: #f1fff5;
      .table {
        tr {
          td {
            border-top: 1px solid rgba(255,255,255,.8);
          }
        }
      }
    }
    &.error {
      .modal-body {
        background-color: #ffd9d2;
      }
    }
  }
  .loader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 51, 172, .9);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 999;
    transition: all ease .3s;
    opacity: 0;
    visibility: hidden;
    .icon {
      margin-bottom: 20px;
    }
    p {
      color: #fff;
      font-size: 20px;
    }
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}




